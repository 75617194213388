import { gql } from '@apollo/client';
import { activePostsetMutationsVar, postsetUpdatedAtVar } from '@/graphql/cache';
import Middleware from '@/graphql/middlewares/Middleware';
import { getISODate } from '@/date';

export default class PostsetMiddleware extends Middleware {
  /**
   * @param {string} postsetId
   * @param {boolean} showLoadingIndicator
   */
  constructor(postsetId, showLoadingIndicator = true) {
    super();
    this.postsetId = postsetId;
    this.showLoadingIndicator = showLoadingIndicator;
  }

  getHeaders(operation) {
    const lockToken = this._getPostsetLockToken(operation.getContext());
    return lockToken ? { 'X-Lock-Token': lockToken } : null;
  }

  beforeRequest(operation) {
    if (this.showLoadingIndicator) {
      const newActivePostsetMutations = new Map(activePostsetMutationsVar());

      if (!newActivePostsetMutations.has(this.postsetId)) {
        newActivePostsetMutations.set(this.postsetId, []);
      }

      newActivePostsetMutations.get(this.postsetId).push(operation.operationName);
      activePostsetMutationsVar(newActivePostsetMutations);
    }
  }

  afterRequest(operation, result) {
    if (this.showLoadingIndicator) {
      const newActiveMutations = new Map(activePostsetMutationsVar());
      const postsetActiveMutations = newActiveMutations.get(this.postsetId);

      if (postsetActiveMutations?.length > 0) {
        newActiveMutations.set(
          this.postsetId,
          postsetActiveMutations.filter((m) => m !== operation.operationName),
        );
        activePostsetMutationsVar(newActiveMutations);
      }
      this._setPostsetUpdatedAt(result);
    }
  }

  _getPostsetLockToken(context) {
    const postset = context.cache.readFragment({
      id: `Postset:${this.postsetId}`,
      fragment: gql`
        fragment PostsetMiddleware on Postset {
          id
          lock(lockIfAccessible: true) {
            token
          }
        }
      `,
    });

    return postset?.lock?.token;
  }

  _setPostsetUpdatedAt(result) {
    if (result.data && !result.errors) {
      const newPostsetUpdatedAt = new Map(postsetUpdatedAtVar());
      newPostsetUpdatedAt.set(this.postsetId, getISODate());
      postsetUpdatedAtVar(newPostsetUpdatedAt);
    }
  }
}
